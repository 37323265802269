// /* You can add global styles to this file, and also import other style files */

// /* Importing Bootstrap SCSS file. */
// @import "bootstrap/scss/bootstrap";

// .panel {
//   margin-bottom: 21px;
//   background-color: #fff;
//   border: 1px solid transparent;
//   border-radius: 4px;
//   -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
//   box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
// }

// .panel {
//   .panel-heading {
//     border-bottom: 2px solid #d3462f;
//     color: #d3462f;
//     font-weight: bold;

//     .panel-title {
//       display: inline-block;
//     }

//     .panel-controls {
//       position: relative;
//       display: inline-block;
//       vertical-align: middle;
//       // float: right;
//       top: -5px;
//     }
//   }
// }

// .panel-default {
//   border-color: #cfdbe2;
// }

// .panel.panel-default {
//   border-top-width: 1px;
// }

// .panel .panel-heading {
//   font-size: 14px;
// }

// .panel-default > .panel-heading {
//   color: #444;
//   background-color: #fff;
//   border-color: #cfdbe2;
// }

// .panel-heading {
//   padding: 10px 15px;
//   border-top-right-radius: 3px;
//   border-top-left-radius: 3px;
//   border-bottom: 1px solid #f5f7fa;

//   a:not(.btn) {
//     color: #337ab7;
//   }

//   .dropdown-menu a {
//     color: white;
//   }
// }

// .panel-body {
//   border-color: transparent;

//   &.collapse {
//     padding: 0px;
//     border-width: 0px;

//     &.in {
//       padding: 0px 20px;
//       border-width: 1px;
//     }
//   }
// }

// .panel .panel-heading {
//   border-bottom: 2px solid #d3462f;
//   color: #d3462f;
//   font-weight: bold;
// }
// .panel .panel-heading .panel-title {
//   display: inline-block;
// }
// .panel .panel-heading .panel-controls {
//   position: relative;
//   display: inline-block;
//   vertical-align: middle;
//   // float: right;
//   top: -5px;
// }

// ///////////////
// .content {
//   margin: 10% 10% 0% 10%; // top right bottom left
// }

// .btn-primary {
//   color: #fff;
//   background-color: #337ab7;
//   border-color: #2e6da4;
// }
// .btn-primary:hover,
// .btn-primary:focus,
// .btn-primary.focus,
// .btn-primary:active,
// .btn-primary.active,
// .open > .dropdown-toggle.btn-primary {
//   color: #fff;
//   background-color: #286090;
//   border-color: #204d74;
// }
// .btn-primary:active,
// .btn-primary.active,
// .open > .dropdown-toggle.btn-primary {
//   background-image: none;
// }
// .btn-primary.disabled,
// .btn-primary[disabled],
// fieldset[disabled] .btn-primary,
// .btn-primary.disabled:hover,
// .btn-primary[disabled]:hover,
// fieldset[disabled] .btn-primary:hover,
// .btn-primary.disabled:focus,
// .btn-primary[disabled]:focus,
// fieldset[disabled] .btn-primary:focus,
// .btn-primary.disabled.focus,
// .btn-primary[disabled].focus,
// fieldset[disabled] .btn-primary.focus,
// .btn-primary.disabled:active,
// .btn-primary[disabled]:active,
// fieldset[disabled] .btn-primary:active,
// .btn-primary.disabled.active,
// .btn-primary[disabled].active,
// fieldset[disabled] .btn-primary.active {
//   background-color: #337ab7;
//   border-color: #2e6da4;
// }
// .btn-primary .badge {
//   color: #337ab7;
//   background-color: #fff;
// }

// .btn-default {
//   color: #333;
//   background-color: #fff;
//   border-color: #ccc;
// }
// .btn-default:hover,
// .btn-default:focus,
// .btn-default.focus,
// .btn-default:active,
// .btn-default.active,
// .open > .dropdown-toggle.btn-default {
//   color: #333;
//   background-color: #e6e6e6;
//   border-color: #adadad;
// }
// .btn-default:active,
// .btn-default.active,
// .open > .dropdown-toggle.btn-default {
//   background-image: none;
// }

// .clearfix:before,
// .clearfix:after,
// .dl-horizontal dd:before,
// .dl-horizontal dd:after,
// .container:before,
// .container:after,
// .container-fluid:before,
// .container-fluid:after,
// .row:before,
// .row:after,
// .form-horizontal .form-group:before,
// .form-horizontal .form-group:after,
// .btn-toolbar:before,
// .btn-toolbar:after,
// .btn-group-vertical > .btn-group:before,
// .btn-group-vertical > .btn-group:after,
// .nav:before,
// .nav:after,
// .navbar:before,
// .navbar:after,
// .navbar-header:before,
// .navbar-header:after,
// .navbar-collapse:before,
// .navbar-collapse:after,
// .pager:before,
// .pager:after,
// .panel-body:before,
// .panel-body:after,
// .modal-footer:before,
// .modal-footer:after {
//   display: table;
//   content: " ";
// }
// .clearfix:after,
// .dl-horizontal dd:after,
// .container:after,
// .container-fluid:after,
// .row:after,
// .form-horizontal .form-group:after,
// .btn-toolbar:after,
// .btn-group-vertical > .btn-group:after,
// .nav:after,
// .navbar:after,
// .navbar-header:after,
// .navbar-collapse:after,
// .pager:after,
// .panel-body:after,
// .modal-footer:after {
//   clear: both;
// }

// .import-button {
//   border: none;
//   padding: 15px 32px;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 14px;
// }

// .pull-right {
//   float: right !important;
// }

// a {
//   color: #047fbe;
//   text-decoration: none;
// }

// a:hover {
//   color: #23527c;
//   text-decoration: underline;
// }

// label {
//   font-weight: 600;
// }

// th {
//   text-align: left;
//   background: #eee;
//   position: sticky;
//   top: 0px;
// }

// .fixTableHeader {
//   border: 1px solid #ddd;
//   width: 100%;
//   height: 350px;
//   overflow-y: auto;
// }

// .me-custom-scrollbar {
//   position: relative;
//   height: 230px;
//   overflow: auto;
// }

// .table-wrapper-scroll-y {
//   display: block;
// }

// .import-body {
//   background-color: #f5f5f5;
//   border-radius: 1px;
//   margin: 10px 0;
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
// }

// .ui-body {
//   margin-left: -15px;
// }

// .li-body {
//   padding: 6px;
// }

// .modal-body {
//   padding: 15px 15px 0px 15px !important;
// }

// .modal-header {
//   padding: 10px !important;
// }

// .searchViewGroup__scope {
//   .search-bar {
//     #filter-categories {
//       position: absolute;
//       right: 100px;
//       top: 12px;
//       z-index: 50;
//       text-align: right;
//     }

//     #filter-search {
//       position: absolute;
//       right: 100px;
//       z-index: 20;
//       top: 2em;
//       margin-top: -1em;
//     }

//     button.search-types {
//       position: absolute;
//       right: 100px;
//       z-index: 20;
//       top: 2em;
//       margin-top: -1.25em;
//     }

//     input {
//       height: 4em;
//       line-height: 3em;
//       width: 100%;
//     }

//     .input-group-addon {
//       width: 80px;

//       button {
//         font-size: 1.5em;
//         background: none;
//         border: none;
//       }
//     }
//   }

//   .panel {
//     margin-top: 20px;
//   }
// }
// .table-border {
//   border-right: 1px solid #ddd;
// }

// .input-group > .input-clear {
//   &:before {
//     font-family: FontAwesome;
//     content: "\f057";
//     font-size: 2.5rem;
//     color: #888;
//   }
//   &:hover {
//     cursor: pointer;
//     text-decoration: none;
//   }
//   position: absolute;
//   top: 12px;
//   z-index: 200;
//   right: 250px;
//   text-decoration: none;
// }

// /* Importing app SCSS file. */
// @import "src/app/app.component.scss";

///////////////////###########********************************
/// 
/// /* You can add global styles to this file, and also import other style files */
@import "assets/theme/select-theme.css";

// Theme customization

button + button {
    margin-left: 5px;
}

.input-group-addon.button {
    padding-top: 4px;
    padding-bottom: 4px;
}

.comma-list {
    span:not(:last-child):after {
        content: ", ";
    }
}

.float-right {
    float: right;
}

.align-right {
    text-align: right;
    padding-right: 10px;
    padding-bottom: 10px;
}

.dataTables_empty {
    display: none;
}

 
#saving-overlay {
    display:none;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15000;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .overlay-body {
        font-weight: bold;
        padding: 5px;
        width: 150px;
        margin: auto;
        border-radius: 5px;
        min-height: 15%;
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
    }
}

#saving-overlay-loader {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15000;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .overlay-body-loader {
        font-weight: bold;
        padding: 5px;
        width: 150px;
        margin: auto;
        border-radius: 5px;
        min-height: 15%;
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
    }
}
body.saving{
    #saving-overlay {
        display: block;
    }
}
body.loading {
    #saving-overlay-loader {
        display: block;
    }
}
body, html {
    width: 100% !important;
    height: 100% !important;
    font-family: 'Arimo', sans-serif;
    margin: 0;
    padding: 0;
}

a, a:active {
    color: #047fbe;
    text-decoration: underline;
    /*font-weight: bold;*/
}
a, a:active :not(div, .list-group) {
    text-decoration: none;
}
[click], [ui-sref], [move-to], a {
    cursor: pointer;
}

.float-right {
    float: right;
}

[disabled] {
    pointer-events: none;
}

.pointer {
    cursor: pointer;
}

.icon-button > * {
    display: inline-block;
}

.align-left {
    text-align: left !important;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
    padding-right: 10px;
    padding-bottom: 10px;
}
.checkbox-labels {
	label {
		display: inline-block;
		padding-right: 10px;
	}
}


/* .alert-danger {
    background-image: -webkit-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
    background-image: -o-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f2dede), to(#e7c3c3));
    background-image: linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
    background-repeat: repeat-x;
    filter: gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
    border-color: #dca7a7;
} */
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    text-shadow: 0 -1px 0 #286090;
    background-image: -webkit-linear-gradient(top, #337ab7 0%, #2b669a 100%);
    background-image: -o-linear-gradient(top, #337ab7 0%, #2b669a 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2b669a));
    background-image: linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
    background-repeat: repeat-x;
    filter: gradient(startColorstr='#ff337ab7', endColorstr='#ff2b669a', GradientType=0);
    border-color: #2b669a;
}
.list-group-item {
    &.active {
        h4 {
            color: white;
            margin-bottom: 5px;
            text-transform: none;
        }
    }
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
	z-index: 2;
	color: #fff !important;
	background-color: #337ab7;
	border-color: #337ab7;
}

.svg {
    display: inline-block;
    height: 25px;
    vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway", sans-serif;
    margin: 0 0 20px 0;
    font-weight: 500;
    color: #4b4b4b;
    text-transform: uppercase;
}


.vertical-middle {
    display: inline-block;
    vertical-align: middle;

    & > * {
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }
}

.table {
    margin-bottom: 0;
}

.bad-status {
    color: darkred;
}

.good-status {
    color: green;
}

.warning-status {
    color: #ecb61a;
}

button.btn-primary svg {
    color: white;
    fill: currentColor;
}

svg {
    path {
        fill: currentcolor;
    }
}

select[required].ng-invalid {
	color: rgba(0,0,0,0.5);
	option {
		&:not([disabled]) {
			color: black;
		}
	}
}

input[required]:not(.ng-valid), select[required]:not(.ng-valid), textarea[required]:not(.ng-valid), .btn-group.required:not(.clicked) label:first-child, .cc-input:not(.ng-valid) {
    border-left: 3px solid darkred !important;
}

.input-group > .input-clear {
    &:before {
        font-family: FontAwesome;
        content: "\f057";
        font-size: 2.5rem;
        color: #888;
    }
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
    position: absolute;
    top: 12px;
    z-index: 200;
    right: 250px;
    text-decoration: none;
}
.contact-phones {

    .form-inline {
        padding-left: 7px;
        padding-bottom: 15px;
    }
}

#loading-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 50000;
}

#loading-bar .bar {
    position: relative;
    height: 3px;
    background-color: #d3462f;
}

.panel-heading .controls,
.modal-header .controls,
.list-group-item .controls {
	float: right;
	margin-top: -5px;
	font-size: 1em;
}
.panel-heading .controls button,
.modal-header .controls button,
.list-group-item .controls button {
	padding: 5px 7px;
	color: white;
}
.input-group-addon {
    &.button {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

/* Panel */

.panel {
    .panel-heading {
        border-bottom: 2px solid #d3462f;
        color: #d3462f;
        font-weight: bold;

        .panel-title {
            display: inline-block;
        }

        .panel-controls {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            float: right;
            top: -5px;
        }
    }
}

// .panel-grid {
//     @include tcStyle($border: none);
//     @include tcDisplay();
// }

/* Modal */

.modal {
    display: block;

    .modal-dialog {
        height: 95%;
        z-index: 10;
        cursor: initial;

        .modal-content {
            max-height: 100%;
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #000000;
            }

            overflow-y: auto;
        }
    }

}

a {
    &.btn {
        text-decoration: none;
    }
}
.comma-list {
    span:not(:last-child):after{

        content: ", ";
    }
}

.btn-group {
    label {
        background-color: white;
        color: #333;
        border-color: #ccc;
    }
}
.btn-group {
	label {
		>.active {
			background-color: #337ab7;
			color: white;
			border-color: #2e6da4;
		}
	}
}

input::-ms-clear {
    display: none;
}


#loading-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 50000;

    min-height: inherit;
}

#loading-bar {
    .bar {
        position: relative;
        height: 3px;
    }
}
/* Mixin */
@mixin border-radius($radius: 5px){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

ul {
    &.imageList {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}
ul {
	&.imageList {
		li {
			cursor: pointer;
			width: 250px;
			float: left;
			padding: 10px;
			margin: 10px;
			border: 1px solid #ddd;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			&.selected {
				background-color: #337ab7;
				border-color: #337ab7;
			}
			div {
				text-align: center;
			}
			img {
				max-width: 100%;
			}
		}
	}
}
.rect{
    border:1px solid #ccc;
    padding-bottom:10px;
}

.look-head
{
    position: absolute;
    margin: -10px 0px 0px 10px;
    background-color: #ffffff;
    padding: 0px 5px 0px 5px;
}

.import-file-select
{
    position: absolute;
    margin: -10px 0px 0px 10px;    
    padding: 0px 5px 0px 24px;
    font-size: 14px;
}

.import-button {    
    border: none;   
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
}

.rgt-field {
    padding-top: 7px;
}

.import-error-highlight {
	background-color: #fe1f1f;
	border: 1px solid #999;
	height: 20px;
}
.list-group-item1 {
	border: none !important;
}

.userRestrictionEditIcon { 
    height: 17px;
    width: 16px;
    padding: 0px 2px;
    margin-top: 10px;
}

.userRestrictionAddIcon {
    margin-top: 8px;
    /*background-color: white;*/
    color: green;
    padding: 0px;
}

.property {
    position: relative;
    display: block;
    padding: 5px 5px;
    margin-bottom: -1px;
    background-color: #fff;
}
.panel-defaultpop > .panel-headingpop {
    color: #444;
    background-color: #fff;
    border-color: #cfdbe2;
}
.evepopover {
    text-align: right;
    margin-bottom: 5px;
    border: none;
    padding: 2px 5px 5px 5px;
}
.eve-btn {
    padding: 5px 10px 7px 5px;
}
.export-btn-right {
    float: right;
    padding: 1px 5px;
}

.exportcolor {
    color: black !important;
}
.export-btn {
    padding: 6px !important;
    margin-right: 3px !important;
}

.gridOptionsrows {
    font-weight: bold;
}

.right20 {
    right: 20% !important;
}

.right31 {
    right: 31% !important;
}

.right70 {
    right: 70% !important;
}

.m-top {
    margin-top: 4% !important;
}

.m-right75 {
    float: right;
    margin-right: 75px !important;
    margin-top: -5px !important;
}

.bgred {
    background-color: #dc1212 !important;
    padding: 4px 8px;
    margin-right: 10px !important;
}

.search_icon {
    right: auto !important;
    top: 32%;
    color: #55555545;
}

.mar-5 {
    margin: 5px !important;
}

.bottom-30 {
    bottom: 30px;
}

.padding-nosize {
    padding: 0px !important;
}

.mar-30 {
   margin-top: 30px;
}

.dateInvalidHightlight {
    border-left: 3px solid darkred !important;
}
.padding5px {
	padding: 5px;
}


.mar-10 {
     margin-top: 10px !important;
}

.right-5 {
    right: 5px !important;
}

.left-5 {
    margin-left: 5px !important;
}

.l-padding {
    padding-left: 30px;
}

.top-p {
    top: 5px;
}

.margin-common {
    margin: 5px;
}
.mar-3 {
    margin-left: 3px;
}

.mar-7 {
    margin-top: 7px;
}

.mar-20 {
    margin-top: 20px;
}
.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
    background-image: none;
    background-color: #3379b6;
    color:white;
}
.mar-r-15 {
    margin-right: 15px;
}

.mar-b-20 {
    margin-bottom: 20px;
}

.module-carrier-layout {
	border: 2px solid #ccc;
	border-radius: 10px;
	padding: 3px;
	margin-top: 8px;
	min-height: 153px;
}

.module-carrier-layout-weigand {
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 3px;
    margin-top: 8px;
    min-height: 250px;
}
.check-align {
	margin-right: 5px !important;
	margin-top: 10px !important;
}
.notifiytapsize {
	margin: 15px;
}
.add-btn-alter {
	color: #1b1a1a !important;
	background-color: #faee09d4 !important;
	border-color: #f40f0f !important;
}
.datetime-align {
	padding: 0px !important;
	bottom: -2px !important;
}
.btn-group {
    label {
        background-color: white;
        color: #333;
        border-color: #ccc;
        &.active {
            background-color: #337ab7;
            color: white;
            border-color: #2e6da4;
        }
    }
}

.list-group.list-group-root {
    padding: 0;
    overflow: hidden;
}

    .list-group.list-group-root .list-group {
        margin-bottom: 0;
    }

    .list-group.list-group-root .list-group-item {
        border-radius: 0;
        border-width: 0px 0 0 0;
    }

    .list-group.list-group-root > .list-group-item:first-child {
        border-top-width: 0;
    }

    .list-group.list-group-root > .list-group > .list-group-item {
        padding-left: 30px;
    }

    .list-group.list-group-root > .list-group > .list-group > .list-group-item {
        padding-left: 45px;
    }

.list-group {
    box-shadow: none !important;
    margin-bottom: 0px !important;
}

// .list-group-item {
//     padding: 7px 0px !important;
// }

.mleft {
    margin-left: 10px;
}

.main {
    max-width: 800px;
}

.log {
    max-height: 300px;
    margin: 20px 0;
    border: 1px solid #000;
    overflow: auto;
}

    .log p {
        font-size: 12px;
        margin: 0 0 5px 0;
    }

code {
    font-size: 14px;
}

h2 {
    margin-top: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}

code pre {
    border: 1px solid gray;
    background-color: #ececec;
    padding: 30px;
}


.form-control-feedback {
right: 15px;
}

// .popover.right {
//     width: 12% !important;
//     height: 28% !important;
//     cursor: pointer;
//     right: 15px;
// }

.drag-transit {
    .arrow-button, .arrowdown {
        display: none;
    }

}

table.dataTable tbody th, table.dataTable tbody td {
    border-left: none ; 
    border-top: none ; 
}

table.dataTable.row-border tbody td{
    border-top:none ;
}
.table-border { 
    border-top: none !important;
    border-left: none !important; 
    
}
        // .table-border {
        //     border-left: 1px solid #ddd;
        //     border-top: none;
        //     padding: 5px;
        //     text-align: center;
        // }

.toast-success {
    color: #3c763d !important;
    background-color: #dff0d8 !important;
    border-color: #d6e9c6 !important;
    background-image: none !important;
  }
  .toast-error {
    background-color: #f2dede !important;
    border-color: #ebccd1 !important;
    color: #a94442 !important;
    background-image: none !important;
    // padding: 10px 10px !important;
    // white-space: nowrap !important;
  }
  .toast-info {
    background-color: #d9edf7 !important;
  border-color: #bce8f1 !important;
  color: #31708f !important;
  background-image: none !important;
  }
  .toast-warning {
    background-color: #fcf8e3 !important;
    border-color: #faebcc !important;
    color: #8a6d3b !important;
    background-image: none !important;
  }
.toast-close-button
{  
    // top:1px !important;
    // padding: 0px 5px !important;
    cursor: pointer !important;
    background: transparent !important;
    border: 0 !important;
    float: right !important;
    font-size: 21px !important;
    font-weight: bold !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
    filter: alpha(opacity=20) !important;
    opacity: .2 !important;
}
.toast-close-button:hover
{
    color: #000 !important;
    text-decoration: none !important;
    cursor: pointer !important;
    filter: alpha(opacity=50) !important;
    opacity: .5 !important;
}
  .dropdown-search::after {
      content: "\f002";
      display: inline-block;
      color: #888;
      font-family: FontAwesome;
      font-size: 14px;
      position: absolute;
      top: 8px;
      right: 13px;
  }

  .ng-clear-wrapper {
    display: none;
  }
    .show_sheduler {
        display: block;
    }

    .hide_sheduler {
        display: none;
    }

    .martop {
        margin-top: 2% !important;
    }

    .activebg-color {
        background-color: #eae9e9;
    }

    .padding-right10 {
        padding-right: 10px !important;
    }

    .positionright {
        float: right;
    }

    .padding-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .padding-0 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .padding10 {
        padding: 10px;
    }

    .padding2 {
        padding: 2px;
    }

    .mleft2 {
        margin-left: 2px;
    }

    .padding-btm10 {
        padding-bottom: 10px;
    }

    a[aria-expanded=false] .fa-caret-down {
        display: none;
    }

    a[aria-expanded=true] .fa-caret-right {
        display: none;
    }

    .disflex {
        display: flex;
    }

    .paddingnone {
        padding: 0px 0px !important;
    }

    .just-padding {
        border: 1px solid #ddd;
    }

    .colorblue {
        color: blue;
    }

    .colorred {
        color: red;
    }

    .colorgreen {
        color: green;
    }
.day-gap {
    display: flex;
    gap: 3px;
    & > label:first-child {
        margin-top: 7px;
    }
}
table.dataTable.row-border tbody td{
    border-top: 0 !important;
}

.toast-container .ngx-toastr{
    width: auto !important;
    padding: 15px 15px 15px 15px !important;
}

.toast-message {
    content-visibility: auto !important;
}

.bs-datepicker-body table td span {
    border-radius :0px !important
}

.bs-datepicker-container{
    padding:0px !important
}
.theme-green .bs-datepicker-body table td span.selected{
    background-color:#3379b6 !important;
}
.bs-datepicker-body table td {
    color: #333 !important;
}
.bs-datepicker-body table th{
    color: #333 !important;
    font-weight: 600 !important;
}
.bs-datepicker-head button {
    color: #333 !important;
    font-weight: 600 !important;
}
.bs-datepicker-body table td span.is-other-month{
    color: #333!important;
}


.bs-timepicker-field {
    // width: 100% !important;
    padding: 0.375rem 0.55rem !important;
}

button{
    cursor: pointer;
}
.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span
{
    color: #9aaec1 !important;
}
.theme-green .bs-datepicker-body table td.week span{
    display: none;
}



table.dataTable thead>tr>th.sorting.sorting_asc:before,
table.dataTable thead>tr>th.sorting.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting.sorting_asc:before,
table.dataTable thead>tr>td.sorting.sorting_asc_disabled:before{
  top: 35% !important;
  right: 1px !important;
  content: "\25B2" !important
}
table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>td.sorting:before,
table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>td.sorting:after
{
  content: '' !important;
}
table.dataTable thead>tr>th.sorting_desc:before,
table.dataTable thead>tr>td.sorting_desc:before,
table.dataTable thead>tr>th.sorting_desc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:before 
{
  content: '' !important;
}
table.dataTable thead>tr>th.sorting.sorting_desc:after,
table.dataTable thead>tr>th.sorting.sorting_desc_disabled:after,
table.dataTable thead>tr>td.sorting.sorting_desc:after,
table.dataTable thead>tr>td.sorting.sorting_desc_disabled:after {
  top: 35% !important;
  right: 1px !important;
  content: "\25BC" !important
}

table.dataTable thead>tr>th.sorting_asc:after,
table.dataTable thead>tr>th.sorting_asc_disabled:after,
table.dataTable thead>tr>td.sorting_asc:after,
table.dataTable thead>tr>td.sorting_asc_disabled:after{
content:'' !important  
}
table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_desc:after {
  opacity: 1 !important
}

table.dataTable thead>tr>th.sorting_desc_disabled:after,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:after,
table.dataTable thead>tr>td.sorting_asc_disabled:before {
  display: none !important
}