body, html {
    width: 100% !important;
    height: 100% !important;
    font-family: 'Arimo', sans-serif;
    margin: 0;
    padding: 0;
}
a, a:active {
    color: #047fbe;
    text-decoration: underline;
}
a, a:active :not(div, .list-group) {
    text-decoration: none;
}
[ng-click], [ui-sref], [move-to] {
    cursor: pointer;
}
.float-right {
    float: right;
}
[disabled] {
    pointer-events: none;
}
.pointer {
    cursor: pointer;
}
.icon-button 
* {
    display: inline-block;
}
.align-left {
    text-align: left !important;
}
.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
    padding-right: 10px;
    padding-bottom: 10px;
}
.checkbox-labels label {
    display: inline-block;
    padding-right: 10px;
}
/* .alert-danger {
    background-image: -webkit-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
    background-image: -o-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f2dede), to(#e7c3c3));
    background-image: linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
    border-color: #dca7a7;
} */
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    text-shadow: 0 -1px 0 #286090;
    background-image: -webkit-linear-gradient(top, #337ab7 0%, #2b669a 100%);
    background-image: -o-linear-gradient(top, #337ab7 0%, #2b669a 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2b669a));
    background-image: linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2b669a', GradientType=0);
    border-color: #2b669a;
}
.list-group-item.active h4 {
    color: white;
    margin-bottom: 5px;
    text-transform: none;
}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    z-index: 2;
    color: #fff !important;
    background-color: #337ab7;
    border-color: #337ab7;
}
/* .svg {
    display: inline-block;
    height: 25px;
    vertical-align: middle;
} */
h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway", sans-serif;
    margin: 0 0 20px 0;
    font-weight: 500;
    color: #4b4b4b;
    text-transform: uppercase;
}

body {
    min-height: 100%;
    position: relative;
}
.vertical-middle {
    display: inline-block;
    vertical-align: middle;
}
.vertical-middle 
* {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}
.table {
    margin-bottom: 0;
}
.bad-status {
    color: darkred;
}
.good-status {
    color: green;
}
.warning-status {
    color: #ecb61a;
}
button.btn-primary svg {
    color: currentColor;
    fill: currentColor;
}
.svg path {
    fill: currentcolor;
}
select[required].ng-invalid {
    color: rgba(0,0,0,0.5);
}
select[required].ng-invalid option:not([disabled]) {
    color: black;
}
input[required]:not(.ng-valid), select[required]:not(.ng-valid), textarea[required]:not(.ng-valid), .btn-group.required:not(.clicked) label:first-child, .cc-input:not(.ng-valid) {
    border-left: 3px solid darkred !important;
}
.input-group 
.input-clear {
    position: absolute;
    top: 12px;
    z-index: 200;
    right: 250px;
    text-decoration: none;
}
.input-group 
.input-clear:before {
    font-family: FontAwesome;
    content: "\f057";
    font-size: 2.5rem;
    color: #888;
}
.input-group 
.input-clear:hover {
    cursor: pointer;
    text-decoration: none;
}
.contact-phones .form-inline {
    padding-left: 7px;
    padding-bottom: 15px;
}
#loading-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 50000;
}
#loading-bar .bar {
    position: relative;
    height: 3px;
    background-color: #d3462f;
}
.panel-heading .controls, .modal-header .controls, .list-group-item .controls {
    float: right;
    margin-top: -5px;
    font-size: 1em;
}
.panel-heading .controls button, .modal-header .controls button, .list-group-item .controls button {
    padding: 5px 7px;
    color: white;
}
.input-group-addon.button {
    padding-top: 4px;
    padding-bottom: 4px;
}
.panel .panel-heading {
    border-bottom: 2px solid #d3462f;
    color: #d3462f;
    font-weight: bold;
}
.panel .panel-heading .panel-title {
    display: inline-block;
}
.panel .panel-heading .panel-controls {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    float: right;
    top: -5px;
}
.modal {
    display: block;
}
.modal .modal-dialog {
    /* height: 95%; */
    z-index: 10;
    cursor: initial;
}
.modal .modal-dialog .modal-content {
    max-height: 100%;
    overflow-y: auto;
}
.modal .modal-dialog .modal-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
.modal .modal-dialog .modal-content::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
.modal .modal-dialog .modal-content::-webkit-scrollbar-thumb {
    background-color: #000000;
}
a.btn {
    text-decoration: none;
}
.comma-list span:not(:last-child):after {
    content: ", ";
}
.btn-group label {
    background-color: white;
    color: #333;
    border-color: #ccc;
}
.btn-group label.active {
    background-color: #337ab7;
    color: white;
    border-color: #2e6da4;
    cursor: pointer;
}
input::-ms-clear {
    display: none;
}
#loading-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 50000;
    min-height: inherit;
}
#loading-bar .bar {
    position: relative;
    height: 3px;
}
ul.imageList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
ul.imageList li {
    cursor: pointer;
    width: 250px;
    float: left;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ddd;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
ul.imageList li.selected {
    background-color: #337ab7;
    border-color: #337ab7;
}
ul.imageList li div {
    text-align: center;
}
ul.imageList li img {
    max-width: 100%;
}
.rect {
    border: 1px solid #ccc;
    padding-bottom: 10px;
    /* cursor: pointer; */
}
.look-head {
    position: absolute;
    margin: -10px 0px 0px 10px;
    background-color: #ffffff;
    padding: 0px 5px 0px 5px;
}
.import-file-select {
    position: absolute;
    margin: -10px 0px 0px 10px;
    padding: 0px 5px 0px 24px;
    font-size: 14px;
}
.import-button {
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
}
.rgt-field {
    padding-top: 7px;
}
.import-error-highlight {
    background-color: #fe1f1f;
    border: 1px solid #999;
    height: 20px;
}
.list-group-item1 {
    border: none !important;
}
.userRestrictionEditIcon {
    height: 17px;
    width: 16px;
    padding: 0px 2px;
    margin-top: 10px;
}
.userRestrictionAddIcon {
    margin-top: 8px;
    color: green;
    padding: 0px;
}
.property {
    position: relative;
    display: block;
    padding: 5px 5px;
    margin-bottom: -1px;
    background-color: #fff;
}
.panel-defaultpop 
.panel-headingpop {
    color: #444;
    background-color: #fff;
    border-color: #cfdbe2;
}
.evepopover {
    text-align: right;
    margin-bottom: 5px;
    border: none;
    padding: 2px 5px 5px 5px;
}
.eve-btn {
    padding: 5px 10px 7px 5px;
}
.export-btn-right {
    float: right;
    padding: 1px 5px;
}
.exportcolor {
    color: black !important;
}
.export-btn {
    padding: 6px !important;
    margin-right: 3px !important;
}
.gridOptionsrows {
    font-weight: bold;
}
.right20 {
    right: 20% !important;
}
.right31 {
    right: 31% !important;
}
.right70 {
    right: 70% !important;
}
.m-top {
    margin-top: 4% !important;
}
.m-right75 {
    float: right;
    margin-right: 75px !important;
    margin-top: -5px !important;
}
.bgred {
    background-color: #dc1212 !important;
    padding: 4px 8px;
    margin-right: 10px !important;
}
.search_icon {
    right: auto !important;
    top: 32%;
    color: #55555545;
}
.mar-5 {
    margin: 5px !important;
}
.bottom-30 {
    bottom: 30px;
}
.padding-nosize {
    padding: 0px !important;
}
.mar-30 {
    margin-top: 30px;
}
.dateInvalidHightlight {
    border-left: 3px solid darkred !important;
}
.padding5px {
    padding: 5px;
}
.mar-10 {
    margin-top: 10px !important;
}
.right-5 {
    right: 5px !important;
}
.left-5 {
    margin-left: 5px !important;
}
.l-padding {
    padding-left: 30px;
}
.top-p {
    top: 5px;
}
.margin-common {
    margin: 5px;
}
.mar-3 {
    margin-left: 3px;
}
.mar-7 {
    margin-top: 7px;
}
.mar-20 {
    margin-top: 20px;
}
.btn-default:active, .btn-default.active, .open 
.dropdown-toggle.btn-default {
    background-image: none;
    background-color: #3379b6;
    color: white;
}
.mar-r-15 {
    margin-right: 15px;
}
.mar-b-20 {
    margin-bottom: 20px;
}
.module-carrier-layout {
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 3px;
    margin-top: 8px;
    min-height: 153px;
}
.module-carrier-layout-weigand {
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 3px;
    margin-top: 8px;
    min-height: 250px;
}
.check-align {
    margin-right: 5px !important;
    margin-top: 10px !important;
}
.notifiytapsize {
    margin: 15px;
}
.add-btn-alter {
    color: #1b1a1a !important;
    background-color: #faee09d4 !important;
    border-color: #f40f0f !important;
}
.datetime-align {
    padding: 0px !important;
    bottom: -2px !important;
}
.bs-datepicker-head button{
    color: #000 !important;
    }
    
    .theme-green .bs-datepicker-head {
        background-color: #fff !important;
    }

    .table-border{
        border-left: 1px solid #ddd;
    border-top: none;
    padding: 5px;
    text-align: center;
    }
    .tableborder{
        border: 1px solid #ccc;
    }
    @media (min-width: 768px) {
        .modal-dialog {
          width: 600px;
          margin: 30px auto;
        }
        .modal-content {
          -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
                  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        }
        .modal-sm {
          width: 300px;
        }
      }
      @media (min-width: 992px) {
        .modal-lg {
          width: 900px;
        }
      }

      .text-left{
        text-align: left;
      }

      /* table.dataTable tbody th, table.dataTable tbody td {
        border-left: 1px solid #ddd;
    border-top: none;
    padding: 5px;
      } */

      table.dataTable tbody th, table.dataTable tbody tr:nth-child(odd) {
        background-color: #f5f5f5;
        /* cursor: pointer; */
    }
    /* table.dataTable tbody th, table.dataTable tbody tr:nth-child(even) {
        cursor: pointer;
    } */
    .m-right5{
        margin-right: 5%;
    }
    .m-left5{
        margin-left: 5%;
    }
    .mar5{
        margin: 5px !important; 
    }
    .m-top{
        margin-top: 10px !important;
    }
.has-error{
    border-color: #a94442 !important;
    color: #a94442 !important;
}
@media screen and (min-width: 1200px) {   
    .dataTables_wrapper .dataTables_info  {
                     width:90%;
                     position: relative;
                  
                    }
                }
                @media screen and (1200px < width < 1380px) { 
                    .dataTables_wrapper .dataTables_info  {
                                    width:70vw;
                                    position: relative;
                                 padding-top: 0.55em;
                                   }
                               }
                 @media screen and (950px < width < 1200px) { 
     .dataTables_wrapper .dataTables_info  {
                     width:68vw;
                     position: relative;
                  
                    }
                }
                @media screen and (750px < width < 950px) { 
     .dataTables_wrapper .dataTables_info  {
                     width:65vw;
                     position: relative;
                  
                    }
                } 

                select {
                    cursor: pointer;
                  }
                  ng-select{
                    cursor: pointer;
                  }
                  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
                    cursor: pointer;
                }
                [is-open]{
                    cursor: pointer;
                }